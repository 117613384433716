import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Redirect } from 'react-router-dom';
import { Form, Input, Button, Divider, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { loginWithEmail, loginWithGoogle } from '../store/user';
import { IntegragenStore } from '../store/index';

const mapDispatch = (dispatch: Dispatch) => ({
  withEmailAndPassword: (values: any) => dispatch(loginWithEmail(values)),
  withGoogle: () => dispatch(loginWithGoogle())
});
const mapState = (store: IntegragenStore) => ({
  isAuthenticated: store.user.isAuthenticated
});

type ILoginProps = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;

const Login = ({ withEmailAndPassword, withGoogle, isAuthenticated }: ILoginProps) => {
  const [loading, load] = useState<boolean>(false);

  const submit = (fn: any) => (values: any) => {
    load(true);
    fn(values);
  };
  return isAuthenticated ? <Redirect to="/" /> : (
    <Spin wrapperClassName="login-container" spinning={loading}>
      <Form
        name="normal_login"
        className="login-form"
        onFinish={submit(withEmailAndPassword)}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        {/* <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="">
            Forgot password
            </a>
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Sign in
          </Button>
        </Form.Item>
        <Divider plain>OR</Divider>
        <Form.Item>
          <Button type="default" onClick={submit(withGoogle)} className="provider-btn">Sign in with Google</Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default connect(mapState, mapDispatch)(Login);