import { Action } from 'redux';
import { from, EMPTY } from 'rxjs';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { map, switchMap, tap, ignoreElements } from 'rxjs/operators';
import * as firebase from 'firebase';

import { IntegragenDeps, IntegragenStore } from './index';

export type IUser = firebase.User & {
  isAuthenticated?: boolean;
};
export type IUserState = IUser;

export enum EUserActions {
  LOGIN = 'LOGIN',
  LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE',
  LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL',
  LOGOUT = 'LOGOUT',
}

export const login = (payload: Partial<firebase.User>) => ({type: EUserActions.LOGIN, payload});
export type ALogin = Action & ReturnType<typeof login>;

export const loginWithEmail = (payload: {email: string, password: string}) => ({type: EUserActions.LOGIN_WITH_EMAIL, payload});
type ALoginWithEmail = Action & ReturnType<typeof loginWithEmail>;

export const loginWithGoogle = () => ({type: EUserActions.LOGIN_WITH_GOOGLE, payload: null});
type ALoginWithGoogle = Action & ReturnType<typeof loginWithGoogle>;

export const logout = () => ({type: EUserActions.LOGOUT, payload: null});
type ALogout = ReturnType<typeof logout> & Action;

export const loginEpic = (action$: ActionsObservable<ALoginWithEmail | ALoginWithGoogle>, _: StateObservable<IntegragenStore>, { auth, providers }: IntegragenDeps) =>
  action$.pipe(
    ofType(EUserActions.LOGIN_WITH_EMAIL, EUserActions.LOGIN_WITH_GOOGLE),
    switchMap(action => {
      const setPersistence = (authenticationMethod: Promise<firebase.auth.UserCredential>) =>
        from(auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => authenticationMethod));
      switch (action.type) {
        case EUserActions.LOGIN_WITH_EMAIL:
          const { email, password } = action.payload as ALoginWithEmail["payload"];
          return setPersistence(auth.signInWithEmailAndPassword(email, password));
        case EUserActions.LOGIN_WITH_GOOGLE:
          return setPersistence(auth.signInWithPopup((providers.get('google') as firebase.auth.OAuthProvider)));
        default:
          return EMPTY;
      }
    }),
    map(({user}) => login(user as firebase.User)),
  );

export const logoutEpic = (action$: ActionsObservable<ALogout>, _: StateObservable<IntegragenStore>, { auth }: IntegragenDeps) =>
  action$.pipe(
    ofType(EUserActions.LOGOUT),
    tap(() => auth.signOut()),
    tap(() => auth.setPersistence(firebase.auth.Auth.Persistence.NONE)),
    ignoreElements(),
  );

const defaultFileState: IUser = {} as IUserState;
type AUser = ALogin | ALogout;

export default (state: IUserState = defaultFileState, action: AUser): IUserState => {
  switch (action.type) {
    case EUserActions.LOGIN:
      return {...state, isAuthenticated: true};
    case EUserActions.LOGOUT:
      return { isAuthenticated: false } as IUserState;
    default:
      return state;
  }
};