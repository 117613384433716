import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, Action } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/storage";
import 'firebase/functions';
import "antd/dist/antd.css";

import App from './App';
//import * as serviceWorker from './serviceWorker';
import { rootReducer, rootEpic, IntegragenStore, IntegragenDeps } from './store';
import { login } from './store/user';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_APPID,
  // databaseURL: process.env.REACT_APP_ "https://myapp-project-123.firebaseio.com",
  // messagingSenderId: process.env.REACT_APP_ "65211879809",
  // measurementId: process.env.REACT_APP_ "G-8GSGZQ44ST"
});

const epicMiddleware = createEpicMiddleware<Action<any>, Action<any>, IntegragenStore, IntegragenDeps>({
  dependencies: {
    storage: firebase.storage(),
    auth: firebase.auth(),
    providers: new Map()
      .set('google', new firebase.auth.GoogleAuthProvider()),
    functions: firebase.app().functions('europe-west1'),
  },
});

function configureStore() {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
}

const store = configureStore();
firebase.auth().onAuthStateChanged(user => user && store.dispatch(login(user)));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
