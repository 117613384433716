import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

interface IUploadButtonProps {
  bucketName: string,
  uploadFile: (file: File, prefix: string) => void,
}

const UploadButtonModal: React.FC<IUploadButtonProps> = ({ bucketName, uploadFile }) => {
  const [showModal, setShowModal] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [folder, setFolder] = useState('');

  const onChange = (e: UploadChangeParam) => {
    const files = e.fileList.map(file => file.originFileObj);

    setFileList(files as File[]);
  }

  const onOk = () => {
    let prefix = folder.trim();
    prefix = prefix.length > 0 && prefix[prefix.length - 1] !== '/' ? `${prefix}/` : prefix;

    fileList.forEach(file => {
      uploadFile(file, prefix)
    })
    setShowModal(false);
  }

  return <div>
    <Button type="primary" danger onClick={() => setShowModal(true)}>
      Upload
    </Button>
    <Modal
      title="Upload files"
      visible={showModal}
      onOk={onOk}
      onCancel={() => setShowModal(false)}
      okText="Upload"
      cancelText="Cancel"
    >
      <Input placeholder="Folder" onChange={e => setFolder(e.target.value)} addonBefore={`gs://${bucketName}/`}/>

      <br/>
      <br/>

      <Dragger customRequest={() => {
      }} multiple showUploadList={{ showDownloadIcon: false }} onChange={onChange}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined/>
        </p>
        <p className="ant-upload-text">Click or drag files in this area</p>
        <p className="ant-upload-hint">
        Ability to select or drag multiple files
        </p>
      </Dragger>

    </Modal>
  </div>
};

export default UploadButtonModal;