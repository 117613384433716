import React from 'react';
import { Collapse } from 'antd';

import './upload-container.css';
import { FileUploadComponent } from "./FileUploadComponent";
import { IFileUpload } from '../../store/storage'

interface IUploadContainerProps {
  fileUploadList: IFileUpload[],
  cancelFileUpload: (uploadUrl: string) => void,
}

export const UploadContainer: React.FC<IUploadContainerProps> = ({ fileUploadList, cancelFileUpload }) => {
  return (
    <div className="file-upload-container">
      <Collapse defaultActiveKey="1">
        <Collapse.Panel header="Files upload" key="1">
          {
            fileUploadList.map(fileUpload => <FileUploadComponent key={fileUpload.uploadUrl} fileUpload={fileUpload} cancelFileUpload={cancelFileUpload}/>)
          }
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}