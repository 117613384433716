import { combineReducers, Action } from 'redux';
import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { catchError } from 'rxjs/operators';

import storage, { 
  IStorageState,
  fetchFilesEpic,
  downloadFilesEpic,
  prepareFileUploadEpic,
  fetchBucketsEpic,
  fetchBucketEpic,
  setBucketEpic,
} from "./storage";
import speed, { fetchDownlinkEpic, ISpeedState } from './speed';
import user, {
  IUserState,
  loginEpic,
  logoutEpic,
} from "./user";
import error, { IErrorState, errorHandler } from './error';

export interface IntegragenStore {
  storage: IStorageState;
  user: IUserState;
  error: IErrorState;
  speed: ISpeedState;
}
export interface IntegragenDeps {
  storage: firebase.storage.Storage;
  auth: firebase.auth.Auth;
  providers: Map<string, firebase.auth.OAuthProvider>;
  functions: firebase.functions.Functions;
}

export const rootEpic = (action$: ActionsObservable<Action>, store$: StateObservable<IntegragenStore>, dependencies: IntegragenDeps) => combineEpics(
  fetchFilesEpic,
  fetchBucketsEpic,
  fetchBucketEpic,
  setBucketEpic,
  downloadFilesEpic,
  prepareFileUploadEpic,
  loginEpic,
  logoutEpic,
  fetchDownlinkEpic,
)(action$, store$, dependencies).pipe(
  catchError(errorHandler(dependencies.storage)),
);

export const rootReducer = combineReducers<IntegragenStore>({
  storage,
  user,
  error,
  speed,
});
