import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Table, Button, Breadcrumb, Menu } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FolderOutlined } from '@ant-design/icons';
import { TableRowSelection } from 'antd/es/table/interface';
import { lookup } from 'mime-types';
/*import {
  useRouteMatch,
  useParams
} from 'react-router-dom';*/

import { IntegragenStore } from '../store/index';
import { downloadFiles, prepareFileUpload, fetchFiles, setBucket, IFile, IBucket } from '../store/storage';
import { convertFileSizeToString, getCurrentFolderName, getFolderPath } from '../utils/string.utils';

import Download from '../components/Download';
import Upload from '../components/upload/UploadButtonModal';

type ITableFile = IFile & { key: string };

const mapState = (state: IntegragenStore) => ({
  files: state.storage.files.map(file => Object.assign(file, {key: file.name})),
  folder: state.storage.folder,
  bucket: state.storage.bucket,
  buckets: state.storage.buckets,
  isLoading: state.storage.isLoading,
});
const mapDispatch = (dispatch: Dispatch) => ({
  download: (records: IFile[]) => dispatch(downloadFiles(records)),
  upload: (file: File, bucket: string, prefix: string) => dispatch(prepareFileUpload({file, bucket, prefix})),
  fetchFolder: (path: string) => dispatch(fetchFiles(path)),
  fetchBucket: (bucket: IBucket) => dispatch(setBucket(bucket)),
});

type FileTableProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const FileTable = ({files, isLoading, folder = '', bucket, buckets, fetchFolder, fetchBucket, download, upload}: FileTableProps) => {
  const [selected, setSelected] = useState<ITableFile[]>([]);
  const overlay = (
    <Menu>
      {buckets.map((bucket, i) => <Menu.Item key={i}><Button type="link" onClick={() => fetchBucket(bucket)}>{bucket.name}</Button></Menu.Item>)}
    </Menu>
  );

  //let { url } = useRouteMatch();
  //let { id } = useParams();
  //console.log(id);

  const columns: ColumnsType<ITableFile> = [
    {
      title: (
        bucket ?
        <Breadcrumb>
          {buckets.length > 1
            ? <Breadcrumb.Item overlay={overlay} dropdownProps={{trigger: ['click'], overlay}} onClick={() => fetchFolder('')}><Button type="link">{bucket.name}</Button></Breadcrumb.Item>
            : <Breadcrumb.Item key="" onClick={() => fetchFolder(getFolderPath(folder, ''))}><Button type="link">{bucket.name}</Button></Breadcrumb.Item>
          }
          {folder.split('/').filter(Boolean).map(name =>
            <Breadcrumb.Item key={name} onClick={() => fetchFolder(getFolderPath(folder, name))}><Button type="link">{name}</Button></Breadcrumb.Item>
          )}
        </Breadcrumb> : 'No bucket'
      ),
      dataIndex: 'name',
      render: (_, record) => record.isFolder ? 
      <span className="cell__folder" onClick={() => fetchFolder(record.name)}><FolderOutlined />{getCurrentFolderName(record.name)}</span>
      : <span>{record.name.split('/').reverse()[record.name.endsWith('/') ? 1 : 0]}</span>
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, record) => record.contentType || lookup(record.name) || 'folder',
    },
    {
      title: 'Size',
      key: 'size',
      render: (_, record) => record.isFolder ? '-' : record.size ? convertFileSizeToString(record.size) : '',
    },
    {
      title: 'Download',
      key: 'download',
      fixed: 'right',
      width: 80,
      className: 'cell__download',
      render: (_, record) => <Download record={record} />
    }
  ];
  const rowSelection: TableRowSelection<ITableFile> = {
    onChange: (_, selectedRows) => setSelected(selectedRows),
    onSelectAll: (isChecked) => setSelected(isChecked ? files : []),
  };

  return (
      <div className="filetable-container">
        <div className="filetable-actions">
          <Button disabled={selected.length === 0} onClick={() => download(selected)} type="primary" style={{ marginBottom: 16 }}>
            Download
          </Button>
          {bucket && bucket.role !== 'roles/storage.objectViewer' && <Upload bucketName={bucket.name} uploadFile={(file, prefix) => upload(file, bucket?.name, prefix)} />}
        </div>
        <Table
          rowSelection={rowSelection}
          size="middle"
          loading={isLoading}
          pagination={{ position: ['bottomCenter'] }}
          columns={columns}
          dataSource={files}
        />
      </div>
  )
};

export default connect(mapState, mapDispatch)(FileTable);