import { Duration } from 'luxon';

const UNITS = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const BYTES_THRESHOLD = 1024;

export const convertFileSizeToString = (bytes: number, precision = 1): string => {
  if (Math.abs(bytes) < BYTES_THRESHOLD) return bytes + ' B';

  let u = -1;
  const r = 10**precision;
  do {
    bytes /= BYTES_THRESHOLD;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= BYTES_THRESHOLD && u < UNITS.length - 1);
  return bytes.toFixed(precision) + ' ' + UNITS[u];
};

export const getEstimatedDownloadTime = (size = 0, downlink = 0): string => {
  if (!downlink || !size) return '-';
  const duration = Duration
    .fromMillis(size / downlink * 1000, { locale: 'fr'})
    .shiftTo('days', 'hours', 'minutes', 'seconds')
    .toObject();
  let result = '~';
  if (duration.days && Math.floor(duration.days) > 0) result += `${Math.floor(duration.days)}j `;
  if (duration.hours && Math.floor(duration.hours) > 0) result += `${Math.floor(duration.hours)}h `;
  if (duration.minutes && Math.floor(duration.minutes) > 0) result += `${Math.floor(duration.minutes)}m `;
  if (duration.seconds && duration.seconds > 0) result += `${duration.seconds.toFixed(3)}s`;
  return result;
};

export const getCurrentFolderName = (folder: string): string =>
  folder.split('/').reverse()[+folder.endsWith('/')];

export const getFolderPath = (path: string, folder: string): string => {
  const result = `${path.slice(0, path.indexOf(folder))}/${folder}`;
  return result.slice(+result.startsWith('/'));
};