import React, {useEffect} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Layout, Button} from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps
} from 'react-router-dom';

import FileTable from './pages/FileTable';
import Login from './pages/Login';
import Error from './components/Error';
import Speed from './components/Speed';
import {UploadContainer} from "./components/upload/UploadContainer";

import './App.scss';
import {logout} from './store/user';
import {IntegragenStore} from './store/index';
import { fetchBuckets, removeFileUpload } from './store/storage';
import logo from './images/integragen_genomics-logo.png';

const mapState = (state: IntegragenStore) => ({
  isAuthenticated: !!state.user.isAuthenticated,
  error: state.error,
  fileUploadList: state.storage.fileUploadList,
});
const mapDispatch = (dispatch: Dispatch) => ({
  logout: () => dispatch(logout()),
  fetchBuckets: () => dispatch(fetchBuckets()),
  cancelFileUpload: (uploadUrl: string) => dispatch(removeFileUpload(uploadUrl))
});

type IAppProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const App = ({isAuthenticated, fetchBuckets, error: {message, source}, fileUploadList, cancelFileUpload, logout}: IAppProps) => {
  useEffect(() => {
    isAuthenticated && fetchBuckets();
  }, [isAuthenticated, fetchBuckets]);

  const PrivateRoute = ({children, ...rest}: RouteProps) => (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location}
            }}
          />
        )
      }
    />
  );

  return (
    <Layout>
      <Layout.Header>
        <h2 className="speed-container"></h2>
        {isAuthenticated && <Button className="btn__logout" onClick={logout} type="primary">Logout</Button>}
        <div className="logo">
          <img src={logo} alt="" />
        </div>
      </Layout.Header>
      <Layout.Content>
        {(message || source) && <Error message={message} source={source}/>}
        <Router>
          <Switch>
            <Route path="/login"><Login/></Route>
            <PrivateRoute exact path="/"><FileTable/></PrivateRoute>
          </Switch>
        </Router>
        {fileUploadList.length > 0 && <UploadContainer fileUploadList={fileUploadList} cancelFileUpload={cancelFileUpload}/>}
      </Layout.Content>
      <Layout.Footer></Layout.Footer>
    </Layout>
  );
};

export default connect(mapState, mapDispatch)(App);
