import React, { useEffect, useState, useCallback } from 'react';
import axios, { AxiosError } from "axios";
import { useDispatch } from 'react-redux';
import { Button, Progress } from 'antd';
import { CloseOutlined, RetweetOutlined } from '@ant-design/icons'

import { IFileUpload, fetchFiles } from '../../store/storage';

const fileFactory = (fileUpload: IFileUpload, cancelToken = axios.CancelToken.source()) => ({
  start: (onUploadProgress: any) => axios.put(fileUpload.uploadUrl, fileUpload.file, {
    onUploadProgress,
    cancelToken: cancelToken.token,
  }),
  getStatus: () => axios.put(fileUpload.uploadUrl, null, {
    headers: {
      'Content-Range': `bytes */${fileUpload.file.size}`,
    },
  }),
  resume: (fileData: Blob, index: number, onUploadProgress: any) => axios.put(fileUpload.uploadUrl, fileData, {
    onUploadProgress,
    headers: {
      'Content-Range': `bytes ${index}-${fileUpload.file.size - 1}/${fileUpload.file.size}`,
    },
    cancelToken: cancelToken.token,
  }),
  cancel: (cb: (url: string) => void) => axios.delete(fileUpload.uploadUrl)
    .finally(() => {
      cb(fileUpload.uploadUrl);
      cancelToken.cancel();
    }),
});

interface IFileUploadComponentProps {
  fileUpload: IFileUpload,
  cancelFileUpload: (uploadUrl: string) => void,
}

export const FileUploadComponent = ({ fileUpload, cancelFileUpload }: IFileUploadComponentProps) => {
  const [loadedPercentage, setLoadedPercentage] = useState(0);
  const [byteUploadIndex, setByteUploadIndex] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [factory] = useState(fileFactory(fileUpload));
  const dispatch = useDispatch();

  const progressHandler = useCallback((event: ProgressEvent) => {
    setLoadedPercentage(Math.floor((byteUploadIndex + event.loaded) / fileUpload.file.size * 100));
    setByteUploadIndex(byteUploadIndex + event.loaded);
  }, [fileUpload, byteUploadIndex, setLoadedPercentage, setByteUploadIndex]);

  const cancelUpload = useCallback(() => factory.cancel(cancelFileUpload), [factory, cancelFileUpload]);

  const resumeUpload = () => {
    setUploadError(false);
    return factory.getStatus()
      .then(() => setLoadedPercentage(100))
      .catch((error: AxiosError) => {
        // if statusCode 308 we should have range header, if not redo getUploadStatusThenResumeUpload
        if (!error || !error.response || !error.response.headers) {
          setUploadError(true);
          return;
        }
        const rangeHeader = error.response.headers.range;
        if (!rangeHeader) {
          setUploadError(true);
          return;
        }
        const [, range] = rangeHeader.split('-');
        const byteUploadIndex = parseInt(range) + 1;
        setByteUploadIndex(byteUploadIndex);
        
        const fileData = fileUpload.file.slice(byteUploadIndex);
        const newFactory = fileFactory(fileUpload);
        return newFactory.resume(fileData, byteUploadIndex, progressHandler);
      });
  };

  useEffect(() => {
    setUploadError(false);
    factory.start(progressHandler)
      .then(() => setLoadedPercentage(100))
      .catch(() => setUploadError(true))
      .finally(() => dispatch(fetchFiles(fileUpload.prefix)));
  // eslint-disable-next-line
  }, /* No dependencies so that the effect runs only once */ []);

  return (
    <div>
      <span>{fileUpload.file.name}</span>
      <Progress percent={loadedPercentage} status={loadedPercentage < 100 ? 'active' : 'success'}/>
      {loadedPercentage < 100 &&
        <div style={{ textAlign: 'right' }}>
          <Button type="primary" danger shape="circle" icon={<CloseOutlined/>} onClick={() => cancelUpload()} size="small"/>
          {uploadError && <Button type="primary" shape="circle" icon={<RetweetOutlined/>} onClick={() => resumeUpload()} size="small"/>}
        </div>
      }
    </div>
  );
}