import { Action } from "redux";
import { ActionsObservable, ofType } from "redux-observable";
import { EStorageActions, AFetchBuckets } from "./storage";
import { map, switchMap, delayWhen, catchError } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
import { from, of } from 'rxjs';

export enum ESpeedActions {
  SET_DOWNLINK = 'SET_DOWNLINK',
  FETCH_DOWNLINK = 'FETCH_DOWNLINK',
}

export interface ISpeedState {
  downlink: number;
  uplink: number;
}

export const fetchDownlink = (payload = null) => ({type: ESpeedActions.FETCH_DOWNLINK, payload});
type AFetchDownlink = ReturnType<typeof fetchDownlink> & Action;

export const setDownlink = (payload: number) => ({type: ESpeedActions.SET_DOWNLINK, payload});
type ASetDownLink = ReturnType<typeof setDownlink> & Action;

export const fetchDownlinkEpic = (action$: ActionsObservable<AFetchBuckets | AFetchDownlink>) =>
  action$.pipe(
    ofType(EStorageActions.FETCH_BUCKETS),
    map(() => new Date().getTime()),
    switchMap(startTime => fromFetch('/speed-test').pipe(
      delayWhen(res => from(res.text())),
      map(res => Number(res.headers.get('Content-Length')) / (new Date().getTime() - startTime) * 1000),
      catchError(() => of(0)),
    )),
    map(setDownlink),
  );

  const defaultSpeedState = {
    uplink: 0,
    downlink: 0,
  };
  type ASpeed = ASetDownLink;

  export default (state: ISpeedState = defaultSpeedState, action: ASpeed): ISpeedState => {
    switch (action.type) {
      case ESpeedActions.SET_DOWNLINK:
        const downlink = action.payload as ASetDownLink["payload"];
        return {...state, downlink };
      default:
        return state;
    }
  };