import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'antd';

import { setError } from '../store/error';

type IErrorProps = {
  message: string;
  source?: string;
};

const Error = ({message, source}: IErrorProps) => {
  const dispatch = useDispatch();
  return (
    <Alert
      message={source || "ERREUR"}
      description={message || "Une erreur inconnue est survenue"}
      type="error"
      showIcon
      closable
      onClose={() => dispatch(setError())}
    />
  );
}

export default Error;