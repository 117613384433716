import { Action } from 'redux';
import { of } from 'rxjs';

import { logout } from './user';

export type IErrorState = any;

export enum EErrorActions {
  SET_ERROR = 'SET_ERROR',
};

export const setError = (message: string = '', source: string = '') => ({type: EErrorActions.SET_ERROR, message, source});
type ASetError = ReturnType<typeof setError> & Action;

export const errorHandler = (storage: firebase.storage.Storage) => (error: any) => {
  switch (error.code) {
    case 'storage/object-not-found':
      // File doesn't exist: go back to the original bucket
      break;
    case 'storage/unauthorized':
    case 'unauthorized':
    case 'unauthenticated':
      // User doesn't have permission to access the object
      return of(logout());
    case 'storage/canceled':
      // User canceled the upload
      break;

    case 'storage/unknown':
      // Unknown error occurred, inspect the server response
      break;
    default:
      break;
  }
  return of(setError(error.message));
}

type AError = ASetError;
export default (_ = {}, action: AError) => {
  switch (action.type) {
    case EErrorActions.SET_ERROR:
      const { message = '', source = '' } = action;
      return { message, source };
    default:
      return {};
  }
};