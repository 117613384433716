import { Action } from 'redux';
import { from } from 'rxjs';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { filter, map, mergeMap, switchMap, mapTo } from 'rxjs/operators';
import streamSaver from 'streamsaver';

import { IntegragenDeps, IntegragenStore } from './index';
import { FileMetadata, FileResponse } from './storage.d';

streamSaver.mitm =  `${process.env.PUBLIC_URL}/custom_mitm.html`;

console.log(streamSaver.mitm);


export type IFile = FileMetadata & {
  isFolder?: boolean;
};

export interface IBucket {
  name: string;
  role: 'roles/storage.objectCreator' | 'roles/storage.objectViewer' | 'roles/storage.objectAdmin' | 'roles/storage.admin';
}

export interface IFileUpload {
  bucket: string;
  file: File;
  uploadUrl: string;
  prefix?: string;
}

export type IStorageState = {
  isLoading: boolean;
  files: IFile[];
  bucket?: IBucket;
  buckets: IBucket[];
  fileUploadList: IFileUpload[];
  folder: string;
};

export enum EStorageActions {
  FETCH_FILES = 'FETCH_FILES',
  SET_FILES = 'SET_FILES',
  SET_LOADING = 'SET_LOADING',
  DOWNLOAD_FILES = 'DOWNLOAD_FILES',
  FETCH_BUCKETS = 'FETCH_BUCKETS',
  FETCH_BUCKET = 'FETCH_BUCKET',
  SET_BUCKETS = 'SET_BUCKETS',
  SET_BUCKET = 'SET_BUCKET',
  SET_FILES_METADATA = 'SET_FILES_METADATA',
  PREPARE_FILE_UPLOAD = 'PREPARE_FILE_UPLOAD',
  ADD_FILE_UPLOAD = 'ADD_FILE_UPLOAD',
  REMOVE_FILE_UPLOAD = 'REMOVE_FILE_UPLOAD',
}


const saveFile = (() => {
  /*const a = document.createElement("a");
  document.body.appendChild(a).setAttribute('style', 'display: none;');*/
  
  
  return async (downloadUrl: string, fileName: string) => {
    const fileStream = streamSaver.createWriteStream(fileName);
    
    fetch(downloadUrl).then((res) => {
      const readableStream = res.body;
      
      // more optimized

      if (readableStream != null){
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream
            .pipeTo(fileStream)
            .then(() => console.log('done writing'));
        }
      }
  
      const writer = fileStream.getWriter();
  
      let reader: any;
      if (res.body != null) {
        reader = res.body.getReader();
      }
      const pump = () =>
        reader
          .read()
          .then((res: any) =>
            res.done ? writer.close() : writer.write(res.value).then(pump),
          );
  
      pump();
    });
    /*const response = await fetch(downloadUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);*/
  };
})();

export const setBuckets = (payload: IBucket[]) => ({type: EStorageActions.SET_BUCKETS, payload});
type ASetBuckets = ReturnType<typeof setBuckets> & Action;

export const setBucket = (payload: IBucket) => ({type: EStorageActions.SET_BUCKET, payload});
type ASetBucket = ReturnType<typeof setBucket> & Action;

export const setFilesList = (payload: FileResponse) => ({type: EStorageActions.SET_FILES, payload});
type ASetFiles = ReturnType<typeof setFilesList> & Action;

export const fetchFiles = (payload: string = '') => ({type: EStorageActions.FETCH_FILES, payload});
type AFetchFiles = ReturnType<typeof fetchFiles> & Action;

export const fetchBuckets = (payload = null) => ({type: EStorageActions.FETCH_BUCKETS, payload});
export type AFetchBuckets = ReturnType<typeof fetchBuckets> & Action;

export const fetchBucket = (payload: IBucket) => ({type: EStorageActions.FETCH_BUCKET, payload});
type AFetchBucket = ReturnType<typeof fetchBucket> & Action;

export const downloadFiles = (payload: IFile[]) => ({type: EStorageActions.DOWNLOAD_FILES, payload});
type ADownloadFiles = ReturnType<typeof downloadFiles> & Action;

export const setLoading = (payload: boolean) => ({type: EStorageActions.SET_LOADING, payload});
type ASetLoading = ReturnType<typeof setLoading> & Action;

export const prepareFileUpload = (payload: { file: File, bucket: string, prefix: string }) => ({
  type: EStorageActions.PREPARE_FILE_UPLOAD,
  payload
});
type APrepareFileUpload = ReturnType<typeof prepareFileUpload> & Action;

const addFileUpload = (payload: IFileUpload) => ({type: EStorageActions.ADD_FILE_UPLOAD, payload});
type AAddFileUpload = ReturnType<typeof addFileUpload> & Action;

export const removeFileUpload = (payload: string) => ({type: EStorageActions.REMOVE_FILE_UPLOAD, payload});
type ARemoveFileUpload = ReturnType<typeof addFileUpload> & Action;

export const fetchBucketsEpic = (action$: ActionsObservable<AFetchBuckets>, _: StateObservable<IntegragenStore>, {functions, storage}: IntegragenDeps) =>
  action$.pipe(
    ofType(EStorageActions.FETCH_BUCKETS),
    switchMap(() => from(functions.httpsCallable('getBucketList')().catch(() => ({data: [{name: storage.ref().bucket}]})))),
    map(({data}) => data),
    map(setBuckets)
    );

export const setBucketEpic = (action$: ActionsObservable<ASetBuckets>, store$: StateObservable<IntegragenStore>) =>
  action$.pipe(
    ofType(EStorageActions.SET_BUCKETS),
    filter(() => !store$.value.storage.bucket),
    map(({payload}) => setBucket(payload[0])),
  );
  
export const fetchBucketEpic = (action$: ActionsObservable<ASetBucket>, _: StateObservable<IntegragenStore>, { functions }: IntegragenDeps) =>
  action$.pipe(
    ofType(EStorageActions.SET_BUCKET),
    filter(({payload}) => !!payload),
    switchMap(({payload}) => from(functions.httpsCallable('getBucketContent')({bucket: payload.name}))),
    map(({data}) => data),
    map(setFilesList),
  );

export const fetchFilesEpic = (action$: ActionsObservable<AFetchFiles>, store$: StateObservable<IntegragenStore>, {functions}: IntegragenDeps) =>
  action$.pipe(
    ofType(EStorageActions.FETCH_FILES),
    switchMap(({payload}) => from(functions.httpsCallable('getBucketContent')({
      bucket: store$.value.storage.bucket?.name,
      prefix: !payload || payload.endsWith('/') ? payload : payload + '/'
    }))),
    map(({data}) => data),
    map(setFilesList),
  );

export const downloadFilesEpic = (action$: ActionsObservable<ADownloadFiles>, store$: StateObservable<IntegragenStore>, {functions}: IntegragenDeps) =>
  action$.pipe(
    ofType(EStorageActions.DOWNLOAD_FILES),
    mergeMap(({payload}) => from(functions.httpsCallable('getSignedUrl')({list: payload, bucket: store$.value.storage.bucket?.name}))),
    map(({data}) => data),
    map(data => data.map(({url, name}: {url: string, name: string}) => {saveFile(url, name.split('/').reverse()[name.endsWith('/') ? 1 : 0])})),
    mapTo(setLoading(false)),
  );

export const prepareFileUploadEpic = (action$: ActionsObservable<APrepareFileUpload>, $store: StateObservable<IntegragenStore>, {functions}: IntegragenDeps) =>
  action$.pipe(
    ofType(EStorageActions.PREPARE_FILE_UPLOAD),
    mergeMap(async ({payload: {file, bucket, prefix}}) => {
      const uploadUrl = await functions.httpsCallable('getResumableUploadUrl')({bucket, fileName: prefix + file.name});
      return {
        file,
        bucket,
        prefix,
        uploadUrl: uploadUrl.data
      };
    }),
    map(addFileUpload)
  );

type AStorage = ASetFiles | ASetBucket | ASetBuckets | AFetchBucket | AFetchBuckets | AFetchFiles | ARemoveFileUpload | AAddFileUpload | ASetLoading;

const defaultStorageState: IStorageState = {
  isLoading: false,
  files: [],
  buckets: [],
  fileUploadList: [],
  folder: '',
};

export default (state: IStorageState = defaultStorageState, action: AStorage): IStorageState => {
  switch (action.type) {
    case EStorageActions.SET_FILES:
      const {items, prefixes} = action.payload as ASetFiles["payload"];
      const bucket = items.length > 0 ? items[0].bucket : '';
      const files = [
        ...prefixes.map(prefix => Object.assign({}, {name: prefix, bucket, isFolder: true})),
        //...items.map(item => Object.assign(item, {fullPath: item.name, name: item.name.split('/').reverse()[item.name.endsWith('/') ? 1 : 0]})),
        ...items.map(item => Object.assign(item, {fullPath: item.name, name: item.name})),
      ];
      return {
        ...state,
        isLoading: false,
        files,
      };
    case EStorageActions.FETCH_FILES:
      return {
        ...state,
        isLoading: true,
        folder: action.payload as AFetchFiles["payload"],
      };
    case EStorageActions.FETCH_BUCKET:
    case EStorageActions.FETCH_BUCKETS:
    case EStorageActions.PREPARE_FILE_UPLOAD:
    case EStorageActions.DOWNLOAD_FILES:
      return {
        ...state,
        isLoading: true,
      };
    case EStorageActions.SET_BUCKET:
      return {
        ...state,
        folder: '',
        files: state.files || [],
        isLoading: true,
        bucket: action.payload as ASetBucket["payload"],
      };
    case EStorageActions.SET_BUCKETS:
      return {
        ...state,
        buckets: action.payload as ASetBuckets["payload"],
        isLoading: false,
      };
    case EStorageActions.ADD_FILE_UPLOAD:
      const fileUpload = action.payload as AAddFileUpload["payload"];
      return {
        ...state,
        fileUploadList: [...state.fileUploadList, fileUpload],
        isLoading: false,
      };
    case EStorageActions.REMOVE_FILE_UPLOAD:
      return {
        ...state,
        fileUploadList: state.fileUploadList.filter(file => file.uploadUrl !== action.payload),
      };
    case EStorageActions.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload as ASetLoading["payload"],
      };
    default:
      return state;
  }
};