import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

//import { getEstimatedDownloadTime } from '../utils/string.utils';
import { IntegragenStore } from '../store/index';
import { downloadFiles, IFile } from '../store/storage';
//import { stringify } from 'querystring';

const mapState = (state: IntegragenStore) => ({
  downlink: state.speed.downlink,
});
const mapDispatch = (dispatch: Dispatch) => ({
  downloadFile: (record: IFile) => dispatch(downloadFiles([record])),
});
type IOwnProps = {
  record: IFile;
};
type IDownloadProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IOwnProps;

const Download = ({downlink, record, downloadFile}: IDownloadProps) => {
  if (!record || record.isFolder) return null;

  return (
    <Tooltip title="Télécharger le fichier">
      <Button type="default" shape="circle" icon={<DownloadOutlined />} size={'small'} onClick={() => downloadFile(record)} />
    </Tooltip>
  );
};

export default connect(mapState, mapDispatch)(Download);